import theme, { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useTacticsAssetsStyle = makeStyles(() =>
  createStyles({
    tacticsHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: `0 ${THEME.REM_SPACES['0.5']}`,
    },
    tacticsAssetsContainer: {
      background: THEME.PALETTES.OTHER.White,
      padding: THEME.REM_SPACES['1'],
      display: 'flex',
      flexDirection: 'column',
      rowGap: THEME.REM_SPACES['1'],
    },
    approveButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      columnGap: THEME.REM_SPACES['0.5'],
      '& a': {
        textDecoration: 'none',
      },
    },
    assetStatusContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    tacticContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexGrow: 2,
      alignItems: 'center',
    },
    topButtonsContainer: {
      display: 'flex',
      columnGap: THEME.REM_SPACES['0.5'],
      justifyContent: 'flex-end',
      marginBottom: theme.custom.REM_SPACES[0.5],
    },
    assetFormContainer: {
      border: '1px solid #E4E8EF',
      padding: THEME.REM_SPACES['1'],
      borderRadius: THEME.REM_SPACES['1'],
      marginBottom: THEME.REM_SPACES['1'],
    },
    filesDisplayContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      marginBottom: THEME.REM_SPACES['1'],
    },
    imageInfoContainer: {
      marginTop: THEME.REM_SPACES['0.5'],
    },
    imageIdSize: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    imageIdContainer: {
      display: 'flex',
      columnGap: THEME.REM_SPACES['0.25'],
    },
    input: {
      flexGrow: 2,
    },
    textInputContainer: {
      display: 'flex',
      columnGap: THEME.REM_SPACES['0.5'],
      alignItems: 'flex-end',
    },
    downloadFieldImageContainer: {
      border: '1px solid #5846CB',
      padding: THEME.REM_SPACES['0.25'],
      borderRadius: THEME.REM_SPACES['0.5'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    downloadImage: {
      width: THEME.REM_SPACES['1.5'],
      height: THEME.REM_SPACES['1.5'],
    },
    imageAssetContainer: {
      border: '1px solid #E4E8EF',
      marginTop: THEME.REM_SPACES['1'],
      padding: THEME.REM_SPACES['1'],
      borderRadius: THEME.REM_SPACES['1'],
      width: '21.37rem',
      minHeight: '15.2rem',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    imageContainer: {
      alignSelf: 'center',
      width: '100%',
      height: '9.88rem',
    },
    image: {
      width: '100%',
      borderRadius: THEME.REM_SPACES['1'],
      height: '100%',
      objectFit: 'contain',
    },
    assetApproveIcon: {
      position: 'absolute',
      top: THEME.REM_SPACES['0.5'],
      right: THEME.REM_SPACES['0.5'],
    },
    infoAndDeleteImageContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    bottomEditModeContainer: {
      marginTop: THEME.REM_SPACES['1'],
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    cancelSaveButtonsContainer: {
      display: 'flex',
      columnGap: THEME.REM_SPACES['0.5'],
      alignItems: 'center',
    },
    deleteEditButtonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: THEME.REM_SPACES['0.5'],
    },
    assetHeader: {
      display: 'flex',
      columnGap: THEME.REM_SPACES[1],
      width: '100%',
      justifyContent: 'space-between',
    },
    imageButtons: {
      display: 'flex',
      columnGap: THEME.REM_SPACES['0.5'],
    },
    labelUppercase: {
      textTransform: 'uppercase',
    },
    inputContainer: {
      border: `1px solid ${theme.custom.PALETTES.BORDER.Extra_Light}`,
      padding: theme.custom.REM_SPACES[1],
      borderRadius: theme.custom.REM_SPACES[1],
      marginBottom: theme.custom.REM_SPACES[1],
    },
    headerText: {
      display: 'flex',
      columnGap: theme.custom.REM_SPACES[0.5],
    },
    errorMessageContainer: {
      marginTop: theme.custom.REM_SPACES[0.5],
    },
    fileName: {
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      lineHeight: 1.5,
      maxWidth: '80%',
      fontSize: '0.7rem',
    },
  }),
);
export const accordionSx = {
  border: '1px solid #E4E8EF',
  padding: THEME.REM_SPACES['1'],
  marginBottom: THEME.REM_SPACES['1'],
  borderRadius: THEME.REM_SPACES['1'],
  boxShadow: 'none',
  '&.MuiAccordion-root:first-of-type': {
    borderRadius: THEME.REM_SPACES['1'],
  },
  '&.MuiAccordion-root:last-of-type': {
    borderRadius: THEME.REM_SPACES['1'],
  },
  '&:before': {
    display: 'none',
  },
};
export const accordionSummarySx = {
  padding: '0',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0)',
  },
};
export const accordionDetailsSx = {
  padding: '0',
};
export const imageIdColorSx = {
  color: '#606670',
};
