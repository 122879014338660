import { Box } from '@mui/material';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { FieldErrors, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { downloadFile } from '#/api/downloadUpload';

import ImageModal from '#/components/campaigns/campaignAssets/ImageModal';
import { imageIdColorSx, useTacticsAssetsStyle } from '#/components/campaigns/campaignAssets/TacticsAssets.style';
import NewButton from '#/components/common/button/NewButton';
import SingleDownloadUpload from '#/components/common/downloadUpload/SingleDownloadUpload';
import { errorTextSx } from '#/components/common/tacticMetadataForm/TacticMetadataForm.style';
import { TypographyWithFontFamily } from '#/components/common/typography/TypographyWithFontFamily';

import { useAppSelector } from '#/hooks';

import { ITacticMetadataDTO, TacticMetadataForm } from '#/interfaces/tacticMetadata/tacticMetadata';

import { fileNameMatch, imageFormats, timestamp, userName } from '#/utils/fileUtils';
import theme from '#/utils/theme';

import DeleteBlueIcon from '../../../assets/DeleteBlueIcon.svg';
import DownloadIconBlue from '../../../assets/DownloadIconBlue.svg';
import UploadBlueIcon from '../../../assets/UploadBlueIcon.svg';

interface IAssetFileInputProps {
  setValue: UseFormSetValue<TacticMetadataForm>;
  input: ITacticMetadataDTO;
  watch: UseFormWatch<TacticMetadataForm>;
  editMode?: boolean;
  tacticId: string;
  errors: FieldErrors<TacticMetadataForm>;
}
export const onDownloadFile = async (fileId: string) => {
  const response = await downloadFile(fileId);
  const match = response?.headers['content-disposition'].match(/filename="([^"]+)"/);
  const link = document.createElement('a');
  link.href = URL.createObjectURL(response?.data);
  link.setAttribute('download', match?.[1]);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

const AssetFileInput: FC<IAssetFileInputProps> = ({ tacticId, setValue, watch, input, editMode = true, errors }) => {
  const existingErrors = errors[String(input.id)]?.message || '';
  const { user } = useAppSelector((state) => state.user);
  const [imageMap, setImageMap] = useState<{
    [key: string]: {
      url: string;
      size: string;
      fileType: 'image' | 'other';
      userNameUploader: string;
      timestamp: string;
      fileName: string;
    };
  }>();
  const classes = useTacticsAssetsStyle();
  const [selectedImage, setSelectedImage] = useState<string>();
  const loadImage = async (imageId: string) => {
    if (!imageId) {
      return;
    }
    const { data, headers } = await downloadFile(imageId);
    const url = URL.createObjectURL(data);
    const fileExtension = headers['content-disposition'].match(fileNameMatch)?.[1]?.split('.')?.[1] || 'pdf';
    const userNameUploaderPattern = headers['content-disposition'].match(userName);
    const userNameUploader = userNameUploaderPattern?.[1] || '';
    const timeStampPattern = headers['content-disposition'].match(timestamp);
    setImageMap(() => ({
      [imageId]: {
        url,
        size: (data?.size / 1024 ** 2).toFixed(2).toString() || '0',
        fileType: imageFormats.includes(fileExtension) ? 'image' : 'other',
        userNameUploader,
        timestamp: dayjs(timeStampPattern?.[1]).format('YYYY-MM-DD HH:MM A'),
        fileName: headers['content-disposition'].match(fileNameMatch)?.[1],
      },
    }));
  };
  useEffect(() => {
    loadImage(watch(String(input.id)));
  }, [watch(String(input.id))]);

  if (!user) {
    //@TODO: Maybe a loader here
    return null;
  }

  return (
    <>
      <Box className={classes.filesDisplayContainer}>
        <TypographyWithFontFamily
          cName={classes.labelUppercase}
          color={theme.custom.PALETTES.TEXT.Gray}
          variant="Label_Base"
        >
          {input.label} {input.required ? '*' : ''}
        </TypographyWithFontFamily>
        <TypographyWithFontFamily variant={'Label_Base'}>{input.helperText}</TypographyWithFontFamily>
        {imageMap?.[watch(String(input.id))]?.fileType === 'image' ? (
          <Box className={classes.imageAssetContainer}>
            <Box className={classes.imageContainer}>
              <img
                alt="Asset Image"
                className={classes.image}
                onClick={() => setSelectedImage(watch(String(input.id)))}
                src={imageMap?.[watch(String(input.id))]?.url}
                style={{
                  cursor: 'pointer',
                }}
              />
            </Box>
            <Box className={classes.imageInfoContainer}>
              <Box className={classes.imageIdSize}>
                <Box className={classes.imageIdContainer}>
                  <TypographyWithFontFamily sx={imageIdColorSx} variant={'Label_Base'}>
                    ID:
                  </TypographyWithFontFamily>
                  <TypographyWithFontFamily variant={'Label_Base'}>{tacticId}</TypographyWithFontFamily>
                </Box>
                <TypographyWithFontFamily variant={'Label_Base'}>
                  {imageMap?.[watch(String(input.id))]?.size} MB
                </TypographyWithFontFamily>
              </Box>
              <Box className={classes.infoAndDeleteImageContainer}>
                <Box>
                  <TypographyWithFontFamily className={classes.fileName} variant={'Label_Base'}>
                    File Name: {imageMap?.[watch(String(input.id))]?.fileName}
                  </TypographyWithFontFamily>
                  <TypographyWithFontFamily variant={'Label_Base'}>
                    Uploaded by {imageMap?.[watch(String(input.id))]?.userNameUploader}
                  </TypographyWithFontFamily>
                  <TypographyWithFontFamily variant={'Label_Base'}>
                    Uploaded at {imageMap?.[watch(String(input.id))]?.timestamp}
                  </TypographyWithFontFamily>
                </Box>
                <Box className={classes.imageButtons}>
                  <img
                    alt="Download Image"
                    onClick={() => onDownloadFile(watch(String(input.id)))}
                    src={DownloadIconBlue}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                  {editMode && (
                    <img
                      alt="Delete Image"
                      onClick={async () => {
                        setValue(String(input.id), '');
                      }}
                      src={DeleteBlueIcon}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box sx={{ width: '35.5rem' }}>
            <SingleDownloadUpload
              customComponent={<NewButton icon={UploadBlueIcon} text={'Upload File'} variant={'Secondary'} />}
              editMode={editMode}
              extensions={
                input.validations?.fileValidation?.format
                  ? input.validations?.fileValidation?.format?.split(',').map((format) => format.trim())
                  : undefined
              }
              fileId={watch(String(input.id))}
              registerName={String(input.id)}
              setValue={setValue}
              size={input.validations?.fileValidation?.size}
              uploadLink={`/file-upload?userId=${user.userId}&userName=${user.firstName} ${user.lastName}`}
            />
          </Box>
        )}
        <TypographyWithFontFamily className={classes.errorMessageContainer} sx={errorTextSx} variant="Default_Base">
          {String(existingErrors)}
        </TypographyWithFontFamily>
      </Box>
      {selectedImage && <ImageModal handleClose={() => setSelectedImage('')} imageId={selectedImage} />}
    </>
  );
};

export default AssetFileInput;
