import { makeStyles, createStyles } from '#/utils/theme';

import { THEME } from '../../../constants/theme/constants';

export const useMultipleDownloadUploadStyles = makeStyles(() =>
  createStyles({
    downloadUploadContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '23.2rem',
      height: '6.5rem',
      border: `1px dashed ${THEME.PALETTES.UI.Secondary[600]}`,
      padding: ` ${THEME.REM_SPACES['1']} ${THEME.REM_SPACES['0.5']}`,
      borderRadius: THEME.REM_SPACES['0.5'],
      background: THEME.PALETTES.UI.Secondary[100],
    },
    browseFile: {
      cursor: 'pointer',
      color: THEME.PALETTES.ICONS.Action_Secondary,
    },
    fileViewContainer: {
      border: `1px solid ${THEME.PALETTES.BORDER.Extra_Light}`,
      display: 'flex',
      justifyContent: 'space-between',
      padding: THEME.REM_SPACES['0.5'],
      marginTop: THEME.REM_SPACES['1'],
      borderRadius: THEME.REM_SPACES['0.5'],
      marginBottom: THEME.REM_SPACES['0.5'],
    },
    imageNameContainer: {
      display: 'flex',
      columnGap: THEME.REM_SPACES['0.5'],
    },
    fileTextContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    uploadDateText: {
      '&.MuiTypography-root': {
        color: THEME.PALETTES.TEXT.Gray,
      },
    },
    buttonsContainer: {
      display: 'flex',
      columnGap: THEME.REM_SPACES['0.5'],
    },
    icon: {
      width: '2.05556rem',
      height: '2.5rem',
    },
    fileButton: {
      cursor: 'pointer',
    },
    grid: {
      columnGap: THEME.REM_SPACES['1'],
      rowGap: THEME.REM_SPACES['1'],
      marginTop: THEME.REM_SPACES['1'],
    },
    fileName: {
      wordBreak: 'break-word',
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      lineHeight: 1.5,
      maxWidth: '80%',
      fontSize: '0.7rem',
    },
  }),
);
